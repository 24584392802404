<template>
  <div>
    <a-row>
      <a-col :span="12">
        <a-spin :spinning="loading">
          <a-descriptions
            :column="1"
            class="iotplt-descriptions iotplt-descriptions-max-6"
          >
            <template slot="title">
              <a-popover title="说明">
                <template slot="content">
                  <p>*短信下发及上行均需计费</p>
                </template>
                <a-space>
                  说明
                  <a-icon type="question-circle" />
                </a-space>
              </a-popover>
            </template>

            <a-descriptions-item label="下发余量(条)">
              {{ smsDeliveryCurrent }}
            </a-descriptions-item>

            <a-descriptions-item label="下发总量(条)">
              {{ smsDeliveryTotalUsage }}
            </a-descriptions-item>
          </a-descriptions>
        </a-spin>
      </a-col>

      <a-col :span="12">
        <a-spin :spinning="loading">
          <a-descriptions
            :column="1"
            class="iotplt-descriptions iotplt-descriptions-max-6"
          >
            <template slot="title">
              <a-popover title="">
                <template slot="content">
                  <pre>&nbsp;</pre>
                </template>
                <a-space>
                  <pre>&nbsp;</pre>
                </a-space>
              </a-popover>
            </template>
            <a-descriptions-item label="上行余量(条)">
              {{ smsReceptionCurrent }}
            </a-descriptions-item>

            <a-descriptions-item label="上行总量(条)">
              {{ smsReceptionTotalUsage }}
            </a-descriptions-item>
          </a-descriptions>
        </a-spin>
      </a-col>
    </a-row>

    <search-agent-sms-res-purchase @submit="submitSearch" />

    <a-table
      size="middle"
      :columns="columns"
      :data-source="dataRecords"
      :loading="loadingTable"
      :pagination="false"
      row-key="id"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchTableData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findAgentSmsesOverview } from '@/api/agent'
import { findResPurchaseRecords } from '@/api/resource_bundle'
import { formatCurrency } from '@/utils/filter'
import SearchAgentSmsResPurchase from '@/views/smses/Search'

export default {
  name: 'AgentFinanceOverview',
  components: {
    SearchAgentSmsResPurchase,
    Pagination
  },
  data() {
    return {
      smsDeliveryTotalUsage: 0,
      smsDeliveryCurrent: 0,
      smsReceptionTotalUsage: 0,
      smsReceptionCurrent: 0,
      dataRecords: [],
      loading: true,
      loadingTable: true,
      pagination: {
        total_count: 0
      },
      query: {
      }
    }
  },
  computed: {
    agentId() {
      if (this.$route.query.agent_id) {
        return parseInt(this.$route.query.agent_id)
      }

      return this.$store.getters.userAgentId
    },

    agentType() {
      return this.$store.getters.userAgentType
    },

    columns() {
      return [
        {
          title: '订购类型',
          dataIndex: 'type'
        },
        {
          title: '有效期开始时间',
          dataIndex: 'begin_time'
        },
        {
          title: '有效期结束时间',
          dataIndex: 'end_time'
        },
        {
          title: '已使用(条)',
          dataIndex: 'usage'
        },
        {
          title: '采购量(条)',
          dataIndex: 'capacity'
        },
        {
          title: '采购量金额(元)',
          dataIndex: 'fee',
          customRender: formatCurrency
        },
        {
          title: '有效性',
          dataIndex: 'effective'
        }
      ]
    }
  },
  created() {
    this.fetchData()
    this.fetchTableData()
  },
  methods: {
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
      this.fetchTableData()
    },

    fetchData() {
      this.loading = true
      findAgentSmsesOverview(this.agentId).then((res) => {
        if (res.code === 0) {
          res.data.forEach(ele => {
            if (ele.type_raw === 0) {
              this.smsDeliveryTotalUsage = ele.total_usage
              this.smsDeliveryCurrent = ele.current
            } else if (ele.type_raw === 1) {
              this.smsReceptionTotalUsage = ele.total_usage
              this.smsReceptionCurrent = ele.current
            }
          })
        }
        this.loading = false
      })
    },

    fetchTableData() {
      this.loadingTable = true
      findResPurchaseRecords(Object.assign({ agent_id: this.agentId, type: '0,1' }, this.query)).then((res) => {
        if (res.code === 0) {
          this.dataRecords = res.data
          this.pagination = res.pagination
        }
        this.loadingTable = false
      })
    }
  }
}
</script>

